import { LOGIN, LOGOUT } from './mutation-types'
import { Module } from 'Vuex'
// import Vue from 'vue'

const mAccount: Module<any, any> = {
  namespaced: true,
  // You can use it as state property
  state: {
    user: JSON.parse(localStorage.getItem('User') || '{ "isAuthenticated": false, "data": null}')
  },
  // Mutation for when you use it as state property
  mutations: {
    [LOGIN] (state, payload) {
      state.user.isAuthenticated = true
      state.user.data = payload.user
      localStorage.setItem('User', JSON.stringify(state.user))
    },
    [LOGOUT] (state) {
      state.user.data = null
      state.user.isAuthenticated = false
      localStorage.setItem('User', JSON.stringify(state.user))
    },
    setUserData (state, payload) {
      state.user.data = payload.user
      localStorage.setItem('User', JSON.stringify(state.user))
    }
  },
  actions: {
    login ({ commit }, data) {
      commit(LOGIN, data)
    },
    logout ({ dispatch, commit }) {
      commit(LOGOUT)
      dispatch('reset', null, { root: true })
    },
    setUserData ({ commit }, user) {
      commit('setUserData', user)
    }
  },
  getters: {
    isAuthenticated: state => {
      return state.user.isAuthenticated
    },
    userName: state => {
      return state.user.fullName
    }
  }
}

export default mAccount
