import Vue from 'vue'
import Multiselect from 'vue-multiselect'

var MultiselectI18n = {
  extends: Multiselect,
  props: {
    placeholder: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    selectLabelText () {
      return this.showLabels
        ? this.$t('multiselect.selectLabel')
        : ''
    },
    selectedLabelText () {
      return this.showLabels
        ? this.$t('multiselect.selectedLabel')
        : ''
    },
    deselectLabelText () {
      return this.showLabels
        ? this.$t('multiselect.deselectLabel')
        : ''
    }
  }
}

Vue.component('multiselect', MultiselectI18n)
