import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/axios-vue'
import './plugins/bootstrap-vue'
import './plugins/toggle-button'
import './plugins/v-calendar'
import './plugins/MultiselectI18n'
import './vue-cookie'
import './plugins/vue-toasted'
import App from './App.vue'
import ApiError from './components/ApiError.vue'
import router from './router'
import store from './store'
import './plugins/vue-moment'
import i18n from './i18n'
import './plugins/vee-validate'
import SpringAuth from './plugins/spring-auth'
import './plugins/vue-simplemde'

import './style/index.scss'

Vue.config.productionTip = false

Vue.component('api-error', ApiError)

Vue.use(SpringAuth, {
  store: store,
  router: router,
  userUrl: 'data/account',
  logoutUrl: '/data/logout'
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted () {
    console.log('Matopat App mouted')

    var vm = this
    // TODO
    /* window.addEventListener('storage', function (event) {
      if (event.key === 'User' && event.newValue !== event.oldValue) {
        vm.$router.go()
      }
    }) */

    // sprawdzamy czy sesja nie wygasla
    this.$auth.refresh()
  }
}).$mount('#app')
