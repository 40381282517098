<template>
  <b-container class="main">
    <div class="card"  v-show="!busy">
      <div class="card-header bg-white d-flex">
        <h4 class="flex-grow-1" v-t="'user.title'"></h4>
        <StatusToogle v-if="formObject"
            class="align-self-center"
            v-model="formObject.blocked"/>
      </div>
      <div class="card-body">
          <api-error :error="error"></api-error>

          <ValidationObserver ref="validObserver" v-slot="{ }">
          <b-form @submit.prevent="onSubmit()" v-if="formObject" novalidate autocomplete="off">

                  <b-form-group
                    label-cols-sm="3"
                    :label="$t('fields.firstname')"
                    label-for="firstname"
                    class="mb-2">
                    <b-form-input id="firstname"
                      autocomplete="user-firstname"
                      v-model="formObject.firstname"
                      disabled>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="3"
                    :label="$t('fields.secondname')"
                    label-for="secondname"
                    class="mb-2">
                    <b-form-input id="secondname"
                      autocomplete="user-secondname"
                      v-model="formObject.secondname"
                      disabled>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="3"
                    :label="$t('fields.username')"
                    label-for="username"
                    class="mb-2">
                    <b-form-input id="username"
                      autocomplete="user-username"
                      v-model="formObject.username"
                      disabled>
                    </b-form-input>
                  </b-form-group>

              <ValidationProvider rules="" name="email" v-slot="{ valid, errors }">
                  <b-form-group
                    label-cols-sm="3"
                    :label="$t('fields.email')"
                    label-for="email"
                    class="mb-2">
                    <b-form-input id="email"
                      type="email"
                      autocomplete="user-email"
                      v-model="formObject.email"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('fields.email')"
                      disabled>
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>

              <ValidationProvider rules="required" name="usertype" v-slot="{ valid, errors }" v-if="$auth.hasRole('ROLE_ADMIN')">
                  <b-form-group
                    label-cols-sm="3"
                    :label="$t('fields.usertype')"
                    label-for="usertype"
                    class="mb-2">
                    <div v-if="!userTypes" class="text-muted">
                      <font-awesome-icon icon="spinner" fixed-width size="lg" pulse/>
                      <span class="sr-only">Loading...</span>
                    </div>
                    <b-form-select v-if="userTypes" id="usertype"
                      v-model="formObject.usertype"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('fields.usertype')">
                      <b-form-select-option v-for="ut in userTypes" :key="ut" :value="ut">{{$t('user-type.'+ut)}}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>

              <div v-if="$auth.hasRole('ROLE_ADMIN') || $auth.hasRole('ROLE_ADMIN_INST_GL')">
              <ValidationProvider rules="required" name="prices" v-slot="{ valid, errors }">
                  <b-form-group label-cols-sm="3" class="mb-2">
                    <b-form-checkbox
                      v-model="formObject.prices"
                      :state="errors[0] ? false : null"
                      name="prices">
                      {{$t('fields.prices')}}
                    </b-form-checkbox>
                    <b-form-invalid-feedback>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>

              <ValidationProvider rules="required" name="pricesComponent" v-slot="{ valid, errors }">
                  <b-form-group label-cols-sm="3" class="mb-2">
                    <b-form-checkbox
                      v-model="formObject.pricesComponent"
                      :state="errors[0] ? false : null"
                      name="pricesComponent">
                      {{$t('fields.pricesComponent')}}
                    </b-form-checkbox>
                    <b-form-invalid-feedback>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>

              <ValidationProvider rules="required" name="seePcl" v-slot="{ valid, errors }">
                  <b-form-group label-cols-sm="3" class="mb-2">
                    <b-form-checkbox
                      v-model="formObject.seePcl"
                      :state="errors[0] ? false : null"
                      name="seePcl">
                      {{$t('fields.seePcl')}}
                    </b-form-checkbox>
                    <b-form-invalid-feedback>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>

              <ValidationProvider rules="required" name="seeNierot" v-slot="{ valid, errors }">
                  <b-form-group label-cols-sm="3" class="mb-2">
                    <b-form-checkbox
                      v-model="formObject.seeNierot"
                      :state="errors[0] ? false : null"
                      name="seePcl">
                      {{$t('fields.seeNierot')}}
                    </b-form-checkbox>
                    <b-form-invalid-feedback>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>
              </div>

              <b-form-group
                v-if="$auth.hasRole('ROLE_ADMIN')"
                label-cols-sm="3"
                label="Instancje:"
                class="mb-2">

                <div v-if="!instance" class="text-muted">
                  <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                  <span class="sr-only">Loading...</span>
                </div>

                <b-form-checkbox-group v-if="instance"
                  name="instance"
                  value-field="name"
                  text-field="description"
                  id="instance"
                  v-model="formObject.instance" :options="instance">
                </b-form-checkbox-group>
              </b-form-group>

                  <hr/>
                  <div class="row">
                    <div class="col-sm-9 offset-sm-3">
                      <button type="submit" class="btn btn-primary mr-1"><font-awesome-icon icon="save" fixed-width/>&nbsp;&nbsp;{{$t('button.save.label')}}</button>
                      <b-button type="button" variant="light" :to="{ name: 'users'}" v-t="'button.cancel.label'"></b-button>
                    </div>
                  </div>

            <b-modal id="modalReject" centered title="Usuń pracownika" cancel-title="Anuluj" ok-title="Usuń" @ok="onDelete">
              <p>Czy na pewno chcecz usunąć pracownika: <b>{{formObject.userNazwisko}} {{formObject.userImie}}</b> ?</p>
            </b-modal>
          </b-form>
          </ValidationObserver>
      </div>
    </div>
    <loading-info :saving="saving.value" :deleting="deleting.value"></loading-info>
  </b-container>
</template>

<script>
import { formMixin, beforeRouteEnter } from '@/mixins/formLazy'
import LoadingInfo from '@/components/LoadingInfo'
import StatusToogle from '@/components/admin/StatusToogle'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  mixins: [formMixin],
  components: { LoadingInfo, StatusToogle, ValidationProvider, ValidationObserver },
  beforeRouteEnter (to, from, next) {
    beforeRouteEnter(to, from, next, 'data/user/', to.params.id)
  },
  created () {
    this.loadUserTypes()
    this.loadInstances()
  },
  data () {
    return {
      userTypes: null,
      instance: null,
      formObject: {
        blocked: false,
        prices: false,
        pricesComponent: false,
        seePcl: false,
        seeNierot: false
      },
      loading: false,
      loadingUserTypes: false,
      loadingInstance: false,
      redirectRoute: { name: 'users' }
    }
  },
  methods: {
    getApiUrl () {
      return 'data/user/'
    },
    loadUserTypes () {
      if (this.userTypes && this.userTypes.length > 0) return

      this.loadingUserTypes = true
      this.$http.get('data/user/types')
        .then((response) => {
          this.userTypes = response.data
          this.loadingUserTypes = false
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    },
    loadInstances () {
      if (this.instance && this.instance.length > 0) return

      this.loadingInstance = true
      this.$http.get('data/user/instances')
        .then((response) => {
          this.instance = response.data
          this.loadingInstance = false
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    }
  }
}
</script>
