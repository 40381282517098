<template>
  <div class="container-fluid main bg-white">
    <div class="row">
      <nav class="col-xl-2 col-md-3 bg-white sidebar">
        <ul class="nav flex-md-column">
          <b-nav-item active-class="active" :to="{ name: 'users'}"><font-awesome-icon class="fa-fw" icon="user" aria-hidden="true"/>&nbsp;{{$t('menu.users')}}</b-nav-item>
          <b-nav-item active-class="active" :to="{ name: 'messages'}"><font-awesome-icon class="fa-fw" icon="envelope" aria-hidden="true"/>&nbsp;{{$t('menu.welcome-messages')}}</b-nav-item>
        </ul>
      </nav>
      <div class="col-xl-10 col-md-9 ml-sm-auto">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
