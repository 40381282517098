<template>
  <toggle-button
    v-bind:value="value"
    :width="90"
    :sync="true"
    :color="colors"
    :labels="labels"
    class="tb-success tb-active-danger"
    v-on:input="$emit('input', $event)"/>
</template>

<script>
export default {
  name: 'StatusToogle',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    reverseMode: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    labels () {
      if (this.reverseMode) {
        return {
          checked: this.$t('toogle-status.blocked'),
          unchecked: this.$t('toogle-status.active')
        }
      } else {
        return {
          checked: this.$t('toogle-status.active'),
          unchecked: this.$t('toogle-status.blocked')
        }
      }
    },
    colors () {
      if (this.reverseMode) {
        return { checked: '#DC3545', unchecked: '#28A745' }
      } else {
        return { checked: '#28A745', unchecked: '#DC3545' }
      }
    }
  }
}
</script>
