import _Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import VueI18n from 'vue-i18n'
import { ToastAction, ToastOptions, ToastObject } from 'vue-toasted'
import { icon, IconLookup, IconName } from '@fortawesome/fontawesome-svg-core'

export const iconCallback = function (el:HTMLElement, i: IconName | IconLookup) {
  var iconEl = icon(i)
  el.innerHTML = iconEl.html[0]
  el.classList.add('pr-2')
  return el
}

/*
Przetwarza nagłówki 'x-of-info*' oraz 'x-of-warn*'
i wyswietla komunikat
*/
const ToastedInterceptor = {
  install (Vue: typeof _Vue, options: ToastedInterceptorOptions) {
    Vue.axios.interceptors.response.use(function (response) {
      var successMsg = response.headers['x-of-info']
      var successMsgType = response.headers['x-of-info-type']
      var successMsgParam = []
      if (successMsgType) {
        successMsgParam = response.headers['x-of-info-param']
        if (successMsgParam) successMsgParam = decodeURIComponent(successMsgParam).split(',')
        successMsg = options.i18n.t(successMsgType, successMsgParam)
      }

      if (successMsg) {
        var actions:ToastAction[] = []

        if (successMsgType && successMsgType.endsWith('created')) {
          var route: any = { name: successMsgParam[0].toLowerCase(), params: {} }
          route.params[route.name + 'Id'] = successMsgParam[1]
          const l = options.router.resolve(route)
          if (l.resolved.matched.length > 0) {
            actions.push({
              text: 'Otwórz',
              push: route
            })
          }
        }

        actions.push({
          icon: 'x',
          text: '',
          onClick: (e: any, toastObject: ToastObject) => {
            toastObject.goAway(0)
          }
        })

        Vue.toasted.show(decodeURIComponent(successMsg), {
          iconPack: 'callback',
          icon: (el: HTMLElement) => iconCallback(el, { prefix: 'fas', iconName: 'info-circle' }),
          action: actions
        })
      }

      var warnMsg = response.headers['x-of-warn']
      var warnMsgType = response.headers['x-of-warn-type']
      if (warnMsgType) {
        var warnMsgParam = response.headers['x-of-warn-param']
        if (warnMsgParam) warnMsgParam = decodeURIComponent(warnMsgParam).split(',')
        warnMsg = options.i18n.t(warnMsgType, warnMsgParam)
      }

      if (warnMsg) {
        Vue.toasted.error(decodeURIComponent(warnMsg), {
          iconPack: 'callback',
          icon: (el: HTMLElement) => iconCallback(el, { prefix: 'fas', iconName: 'exclamation-triangle' }),
          action: [{
            icon: 'x',
            text: '',
            onClick: (e: any, toastObject: ToastObject) => {
              toastObject.goAway(0)
            }
          }]
        })
      }
      return response
    })
  }
}

export class ToastedInterceptorOptions {
  i18n!: VueI18n;
  router!: VueRouter
}

export default ToastedInterceptor
