import Vue from 'vue'
import VCalendar from 'v-calendar'
import DateRangePicker from '@/components/DateRangePicker'

Vue.use(VCalendar, {
  locale: 'pl-PL',
  popoverVisibility: 'focus'
})

Vue.component('date-range-picker', DateRangePicker)
