import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

if (process.env.NODE_ENV === 'production') {
  console.log('Welcome to production')
} else {
  console.log('Welcome to development')
}

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': process.env.VUE_APP_API_CORS_URL
  },
  withCredentials: true
})

Vue.use(VueAxios, http)

export default http
