<template>
  <div class="alert alert-danger" v-if="error">
    <div class="alert-heading" v-if="error.typeCode" key="type-code" v-html="$t(error.typeCode)"/>
    <h6 class="alert-heading" v-else  v-html="$t('error.api')"/>

    <p key="exception" v-if="error.exception && $te('exception.'+error.exception)" v-html="$t('exception.'+error.exception, {msg: error.message})"/>
    <p v-else-if="error.exception" v-html="$t('exception.default')"/>

    <ul v-else-if="Array.isArray(error.message)">
      <li v-for="msg in error.message" :key="msg">
        {{ msg }}
      </li>
    </ul>

    <div v-else>{{ error.message }}</div>

    <ul v-if="error.fieldErrors">
      <li v-for="e in error.fieldErrors" :key="e.message">
        {{ e.message }}
      </li>
    </ul>

    <b-link v-if="error.exception && error.trace" v-b-modal.modal1 aria-hidden="true">
      <font-awesome-icon icon="chevron-circle-right"></font-awesome-icon>
    </b-link>

    <b-modal size="xl" id="modal1" hide-footer :title="error.exception" style="max-width: 1000px;">
        <h6>{{ error.message }}</h6>
        <pre style="max-height: 50vh"><code>{{ error.trace }}</code></pre>
    </b-modal>
</div>
</template>

<script>
export default {
  name: 'ApiError',
  props: {
    error: Object
  }
}
</script>

<style>

</style>
