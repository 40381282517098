<template>
  <div>
    <div class="d-flex mb-0 mt-2">
      <h5 class="flex-grow-1">{{$t('wyroby.set-elements')}}</h5>
    </div>

    <api-error :error="error"></api-error>

    <b-table responsive="md"
      ref="table"
      :small=true
      :items="dataProvider"
      :fields="fields"
      sort-by="itemCode"
      :no-provider-sorting="true"
      :sort-desc="false"
      :api-url="apiUrl">

      <template v-slot:cell(parameters)="row">
        <span v-for="(value, name) in row.item" :key="name">
          <span v-if="!skipFields.has(name) && value">
            <span v-if="$te('fields.'+name)" >{{$t('fields.'+name)}}: {{value}};</span>
            <span v-else>{{name}}: {{value}};</span>
          </span>
        </span>
      </template>

      <template v-slot:cell(itemCode)="row">
        <b-link :to="{ name: 'surowiecWyrobu', params: { id: row.item.inventoryitemid}}" :title="$t('button.view.title')" class="text-dark">
          {{row.item.itemCode}}
        </b-link>
      </template>

    </b-table>

  </div>
</template>

<script>
import tableMixin from '@/mixins/table'
import { mapGetters } from 'vuex'

export default {
  mixins: [tableMixin],
  props: {
    inventoryItemId: {
      type: Number
    }
  },
  data () {
    return {
      skipFields: new Set(['inventoryitemid', 'itemCode', 'itemDesc', 'itemUom', 'quantity', 'assignment', 'price'])
    }
  },
  watch: {
    currentLang (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$refs.table.refresh()
      }
    }
  },
  methods: {
    dataProvider (ctx) {
      this.error = null

      if (!this.inventoryItemId) return []

      let promise = this.$http.get(ctx.apiUrl)

      return promise.then((response) => {
        let items = response.data
        return (items || [])
      }).catch((error) => {
        this.onError(error)
        return []
      })
    }
  },
  computed: {
    fields () {
      return [
        { key: 'itemCode', label: this.getColumnLabel('itemCode'), sortable: true, tdClass: 'tdMinItemWidth' },
        { key: 'itemDesc', label: this.getColumnLabel('itemDesc'), sortable: true },
        { key: 'quantity', label: this.getColumnLabel('quantity'), sortable: true },
        // { key: 'itemUom', label: this.getColumnLabel('itemUom'), sortable: true },
        { key: 'parameters', label: this.getColumnLabel('parameters'), sortable: false }
      ]
    },
    apiUrl () {
      return 'data/itemSet/' + this.inventoryItemId + '/items'
    },
    ...mapGetters({
      currentLang: 'settings/currentLang'
    })
  }
}
</script>

<style>

</style>
