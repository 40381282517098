<template>
  <b-container class="main">
    <div class="card" v-show="!busy">
      <div class="card-header bg-white d-flex">
        <h4 class="flex-grow-1">{{ $t('welcome-message.title') }}</h4>
        <StatusToogle v-if="formObject"
          class="align-self-center"
          :reverseMode="false"
          v-model="formObject.active"/>
      </div>

      <div class="card-body">
        <api-error :error="error" />

        <ValidationObserver ref="validObserver" v-slot="{ }">
          <b-form @submit.prevent="onSubmit()" v-if="formObject" novalidate autocomplete="off">

          <ValidationProvider rules="required" name="instance" v-slot="{ valid, errors }" v-if="$auth.hasRole('ROLE_ADMIN')">
            <b-form-group
              label-cols-sm="3"
              :label="$t('fields.instance')"
              label-for="instance"
              class="mb-4">
              <b-form-select v-if="instances"
                v-model="formObject.instance"
                :state="errors[0] ? false : (valid ? true : null)"
                :placeholder="$t('fields.instance')">
                <b-form-select-option v-for="int in instances" :key="int.name" :value="int.name">{{ int.description }}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required|max:3999" name="content">
            <b-form-group
              :label="$t('fields.content')"
              label-for="content">
              <vue-simplemde preview-class="bg-white overflow-visible"
                v-model="formObject.content"
                :configs="markdownConfig"
                ref="markdownEditor">
              </vue-simplemde>
            </b-form-group>
          </ValidationProvider>

          <hr/>

          <div class="d-flex justify-content-center align-items-center">
            <button type="submit" class="btn btn-primary mr-2">
              <font-awesome-icon icon="save" class="fa-fw mr-2" fixed-width />
              {{ $t('button.save.label') }}
            </button>
            <b-button type="button" variant="danger" class="mr-2" @click="onDelete" v-if="$route.params.id !== 'add'">
              <font-awesome-icon icon="trash" class="fa-fw mr-2" aria-hidden="true"/>
              {{ $t('button.delete.label') }}
            </b-button>
            <b-button type="button" variant="secondary" :to="{ name: 'messages'}" v-t="'button.cancel.label'"></b-button>
          </div>

          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <loading-info :saving="saving.value" :deleting="deleting.value"></loading-info>
  </b-container>
</template>

<script>
import { formMixin, beforeRouteEnter } from '@/mixins/formLazy'
import StatusToogle from '@/components/admin/StatusToogle'
import LoadingInfo from '@/components/LoadingInfo'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  mixins: [formMixin],
  components: { StatusToogle, ValidationObserver, ValidationProvider, LoadingInfo },
  beforeRouteEnter (to, from, next) {
    beforeRouteEnter(to, from, next, 'data/welcome-message/', to.params.id)
  },
  data () {
    return {
      formObject: {
        active: false
      },
      markdownConfig: {
        status: ['lines', 'words', {
          className: 'letters',
          defaultValue: (el) => {
            el.innerHTML = 'letters: 0'
          },
          onUpdate: (el) => {
            if (this.formObject.content) {
              el.innerHTML = 'letters: ' + this.formObject.content.length
            } else {
              el.innerHTML = 'letters: 0'
            }
          }
        }],
        toolbar: ['bold', 'italic', 'heading', '|', 'quote', 'unordered-list', 'ordered-list', '|', 'link', 'horizontal-rule', '|', 'guide']
      },
      instances: null,
      redirectRoute: { name: 'messages' }
    }
  },
  created () {
    if (this.$auth.hasRole('ROLE_ADMIN')) {
      if (this.instances && this.instances.length > 0) return

      this.$http.get('data/user/instances')
        .then((response) => {
          this.instances = response.data
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    }
  },
  mounted () {
    if (this.$auth.hasRole('ROLE_ADMIN')) {
      if (this.$route.params.id === 'add') {
        this.formObject.instance = null
      }
    } else if (this.$auth.hasRole('ROLE_ADMIN_INST_RU')) {
      this.formObject.instance = 'RU'
    } else {
      this.formObject.instance = 'GL'
    }
  },
  methods: {
    getApiUrl () {
      return 'data/welcome-message/'
    }
  }
}
</script>

<style>
.vue-simplemde .CodeMirror, .vue-simplemde .CodeMirror-scroll {
  min-height: 50px;
  border-width: 0px;
}
</style>
