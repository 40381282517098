import { Module } from 'vuex'

const SET_TABLE_STATE = 'SET_TABLE_STATE'
const CLEAR = 'CLEAR'

const mTable: Module<any, any> = {
  namespaced: true,
  state: {
    tables: []
  },
  getters: {
    getTable: (state) => (name: string) => {
      return state.tables[name]
    }
  },
  mutations: {
    [SET_TABLE_STATE] (state, data) {
      state.tables[data.name] = data.query
    },
    [CLEAR] (state) {
      state.tables = []
    }
  },
  actions: {
    setTableState ({ commit }, data) {
      commit(SET_TABLE_STATE, data)
    },
    reset: {
      root: true,
      handler ({ commit }) {
        commit(CLEAR)
      }
    }
  }
}

export default mTable
