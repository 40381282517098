<template>
  <transition name="component-fade">
      <h4 class="text-center text-secondary mb-4 mt-2" v-if="busy">
        <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i> Trwa zapisywanie ... </span>
        <span v-else-if="deleting"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i> Trwa usuwanie ... </span>
        <span v-else><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i> Trwa ładowanie ... </span>
      </h4>
  </transition>
</template>

<script>
export default {
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    deleting: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    busy: {
      get: function () {
        return this.saving || this.deleting || this.loading
      }
    }
  }
}
</script>

<style>

</style>
