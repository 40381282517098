<template>
  <b-container class="main">
    <div class="card">

      <div class="card-body">
        <div class="row">
          <b-col>
            <h5 class="card-title flex-grow-1">{{ $t('menu.welcome-messages') }}</h5>
          </b-col>
          <b-col>
            <ValidationObserver ref="searchObserver" v-slot="{ }">
              <b-form class="" @submit.prevent="applyFilter" autocomplete="off">
                <b-row>
                  <b-col>
                    <b-form-select
                      v-if="instances && $auth.hasRole('ROLE_ADMIN')"
                      v-model="filter.instance.value.equals"
                      :placeholder="$t('fields.usertype')">
                      <b-form-select-option
                        v-for="instance in instances"
                        :key="instance.name"
                        :value="instance.name">
                        {{ instance.description }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <b-col class="align-self-center">
                    <b-form-checkbox
                      name="standard"
                      type="text"
                      :unchecked-value=null
                      v-model="filter.active.value.equals">{{$t('fields.active')}}</b-form-checkbox>
                  </b-col>
                  <b-button class="mb-2 mr-2" variant="primary" type="submit"
                    v-b-tooltip.hover="{ title: $t('button.search.label'), placement: 'bottomleft', variant: 'info' }">
                    <font-awesome-icon icon="search"/>
                  </b-button>
                  <b-button class="mb-2 mr-2" variant="secondary" type="button" @click="resetFilter" v-if="$auth.hasRole('ROLE_ADMIN')"
                    v-b-tooltip.hover="{ title: $t('button.filterClear.title'), placement: 'bottomleft', variant: 'info' }">
                    <font-awesome-icon icon="times" />
                  </b-button>
                  <b-button class="mb-2 mr-1" variant="success" type="button"
                    :to="{ name: 'message', params: { id: 'add' } }"
                    v-b-tooltip.hover="{ title: $t('button.add.title'), placement: 'bottomleft', variant: 'info' }">
                    <font-awesome-icon icon="plus"/>
                  </b-button>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </div>

        <api-error :error="error" />

        <b-table ref="table" responsive="md" :small="true"
          :items="dataProvider"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filterStr"
          api-url="data/welcome-message">

          <template v-slot:cell(content)="row">
            {{ row.item.content.slice(0, 30) }}
          </template>

          <template v-slot:cell(active)="row">
            <font-awesome-icon v-if="row.item.active" icon="check" fixed-width aria-hidden="true" />
          </template>

          <template v-slot:cell(actions)="row">
            <span id="show-content" v-b-tooltip.hover="{ title: $t('button.view.title'), placement: 'bottomleft', variant: 'info' }">
              <b-link class="mr-2" @click="toggleDetails(row)">
                <font-awesome-icon icon="sticky-note" class="font-weight-bold" fixed-width aria-hidden="true" />
              </b-link>
            </span>
            <b-link :to="{ name: 'message', params: { id: row.item.id } }" :title="$t('button.update.title')">
              <font-awesome-icon icon="edit" class="font-weight-bold" fixed-width aria-hidden="true" />
            </b-link>
          </template>

          <template #row-details="row">
            <vue-simplemde preview-class="bg-white overflow-visible"
              :configs="markdownConfig"
              :value="row.item.content"
              ref="markdownEditor">
          </vue-simplemde>

          </template>

        </b-table>

        <b-row>
          <b-col md="7" class="my-1">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
          </b-col>
          <b-col md="5" class="my-1">
            <TableSize v-model="perPage" :totalRows="totalRows"/>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import TableSize from '@/components/TableSize.vue'
import tableMixin from '@/mixins/table'

export default {
  components: { ValidationObserver, TableSize },
  mixins: [tableMixin],
  data () {
    return {
      sortBy: 'active',
      filter: {
        instance: { fieldType: 'input', value: { equals: null } },
        active: { fieldType: 'input', value: { equals: null } }
      },
      filterStr: JSON.stringify(this.filter),
      instances: null,
      markdownConfig: {
        status: false,
        toolbar: false,
        spellChecker: false,
        autoDownloadFontAwesome: false
      }
    }
  },
  created () {
    this.loadInstances()
  },
  computed: {
    fields () {
      const fields = [
        { key: 'content', label: this.getColumnLabel('content'), sortable: false },
        { key: 'active', label: this.getColumnLabel('active'), sortable: true },
        { key: 'actions', label: '', class: 'text-right' }
      ]

      if (this.$auth.hasRole('ROLE_ADMIN')) {
        fields.unshift({ key: 'instance', label: this.getColumnLabel('instance'), sortable: true })
      }

      return fields
    }
  },
  methods: {
    loadInstances () {
      if ((this.instances && this.instances.length > 0) || !this.$auth.hasRole('ROLE_ADMIN')) return

      this.$http.get('data/user/instances')
        .then((response) => {
          this.instances = response.data
        })
        .catch((error) => {
          this.error = error.response.data
        })
    },
    toggleDetails (row) {
      if (row.item._showDetails) {
        this.$refs.table.$set(row.item, '_showDetails', false)
      } else {
        this.$refs.table.localItems.forEach(singleRow => {
          this.$refs.table.$set(singleRow, '_showDetails', false)
        })

        this.$nextTick(() => {
          this.$refs.table.$set(row.item, '_showDetails', true)
          this.$nextTick(() => {
            this.$refs.markdownEditor.simplemde.togglePreview()
          })
        })
      }
    }
  }
}
</script>
