<template>
<b-container class="main bg-white">
  <div v-if="$auth.hasRole('ROLE_ADMIN')" class="card-body">
    <div class="row justify-content-end">
      <b-button v-b-toggle.collapse-1 variant="dark">{{$t('button.editHelpPage.label')}}</b-button>
    </div>
    <div class="pt-1">
    <b-collapse id="collapse-1">
      <b-card>
        <api-error :error="error"></api-error>
        <ValidationObserver ref="validObserver" v-slot="{ }">
          <b-form @submit.prevent="onSubmit()" v-if="formObject" novalidate autocomplete="off">
            <b-form-textarea id="firstname"
              v-model="formObject.stringValue"
              rows="20">
            </b-form-textarea>
            <div class="row">
              <div class="col-sm-9 offset-sm-3">
                <button type="submit" class="btn btn-primary mr-1"><font-awesome-icon icon="save" fixed-width/>&nbsp;&nbsp;{{$t('button.save.label')}}</button>
                <b-button type="button" variant="light" :to="{ name: 'home'}" v-t="'button.cancel.label'"></b-button>
                <b-link href="https://www.markdownguide.org/basic-syntax/" target="_blank">markdown</b-link>
              </div>
            </div>
            <b-modal id="modalReject" centered :title="$t('button.delete.title')" :cancel-title="$t('button.cancel.label')" :ok-title="$t('button.delete.title')" @ok="onDelete">
              <p>{{$t('button.delete.title')}}?</p>
            </b-modal>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-collapse>
    </div>
  </div>
  <loading-info :saving="saving.value" :deleting="deleting.value"></loading-info>
  <div class="row no-gutters">
    <markdown-it-vue class="md-body" :content="formObject.stringValue" />
  </div>
</b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { formMixin, beforeRouteEnter } from '@/mixins/formLazy'
import MarkdownItVue from 'markdown-it-vue'
import LoadingInfo from '@/components/LoadingInfo'
import { ValidationObserver } from 'vee-validate'

export default {
  mixins: [formMixin],
  components: { MarkdownItVue, LoadingInfo, ValidationObserver },
  data () {
    return {
      content: '# Pomoc',
      formObject: {
        blocked: false,
        prices: false,
        stringValue: ''
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    beforeRouteEnter(to, from, next, 'data/param/getParam?name=', 'HELP')
  },
  computed: {
    ...mapGetters({
      currentLang: 'settings/currentLang'
    })
  },
  methods: {
    getApiUrl () {
      return 'data/param/'
    },
    getFullUrl: function (tmpUrl) {
      return this.axios.defaults.baseURL + tmpUrl
    }
  }
}
</script>
