export default {
  data () {
    return {
      error: null,
      sortBy: null,
      sortByInit: this.sortBy,
      sortDesc: false,
      sortDescInit: this.sortDesc,
      totalRows: 0,
      totalPages: 0,
      perPage: 20,
      currentPage: 1,
      tableBusy: false,
      allowedFilterValues: {},
      allowedSubGroups: {}
    }
  },
  created () {
    this.sortByInit = this.sortBy
    this.sortDescInit = this.sortDesc
    var query = this.$store.getters['table/getTable'](this.$route.name)
    if (query) {
      this.sortBy = query.sortBy
      this.sortDesc = query.sortDesc
      this.perPage = query.perPage
      this.filter = JSON.parse(query.filter, this.jsonReviver)
      this.filterStr = query.filter
      this.totalRows = query.perPage * query.currentPage
      this.currentPage = query.currentPage
    } else {
      this.filterStr = JSON.stringify(this.filter)
    }
  },
  methods: {
    getColumnLabel (key) {
      return this.$t('fields.' + key)
    },
    isValid (name, scope) {
      if (scope) {
        return this.formFields[scope] && this.formFields[scope][name] && this.formFields[scope][name].valid
      } else {
        return this.formFields[name] && this.formFields[name].valid
      }
    },
    jsonReviver (key, value) {
      if (typeof value === 'string' && (key === 'start' || key === 'end')) {
        return this.$moment(value).toDate()
      }
      if (typeof value === 'object' && value !== null && value.fieldType === 'singleDate') {
        return { fieldType: 'singleDate', value: this.$moment(value.value).toDate() }
      }
      return value
    },
    getFilterParam (filter) {
      var filterParam = {}

      for (var field in filter) {
        switch (filter[field].fieldType) {
          case 'selectEnum' :
            if (filter[field].value) {
              if (filter[field].value.constructor === Array && filter[field].value.length > 0) {
                filterParam[field + '.in'] = filter[field].value.map(x => x.value).join(',')
              } else if (filter[field].value.id) {
                filterParam[field + '.equals'] = filter[field].value.value
              }
            }
            break
          case 'select' :
            if (filter[field].value) {
              var key = filter[field].key ? filter[field].key : 'id'
              if (filter[field].value.constructor === Array) {
                filterParam[field + '.in'] = filter[field].value.map(x => x[key]).join(',')
              } else if (filter[field].value[key]) {
                filterParam[field + '.equals'] = filter[field].value[key]
              }
            }
            break
          case 'dateRange':
            if (filter[field].value) {
              if (filter[field].format === 'ISO_UTC') {
                filterParam[field + '.GreaterOrEqualThan'] = this.$moment(filter[field].value.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
                filterParam[field + '.LessOrEqualThan'] = this.$moment(filter[field].value.end).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
              } else {
                filterParam[field + '.GreaterOrEqualThan'] = this.$moment(filter[field].value.start).format(this.$moment.HTML5_FMT.DATE)
                filterParam[field + '.LessOrEqualThan'] = this.$moment(filter[field].value.end).format(this.$moment.HTML5_FMT.DATE)
              }
            }
            break
          case 'singleDate':
            if (filter[field].value) {
              filterParam[field] = this.$moment(filter[field].value).format(this.$moment.HTML5_FMT.DATE)
            }
            break
          case 'complex':
            var complexFilter = filter[field].value
            if (complexFilter && !Array.isArray(complexFilter)) {
              filterParam[complexFilter.field] = complexFilter.value
            }
            if (complexFilter && Array.isArray(complexFilter)) {
              complexFilter.forEach(function (complexField) {
                filterParam[complexField.field] = complexField.value
              })
            }
            break
          case 'rok':
            if (filter[field].value) {
              if (filter[field].format === 'ISO_UTC') {
                filterParam[field + '.GreaterOrEqualThan'] = this.$moment().year(filter[field].value).startOf('year').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
                filterParam[field + '.LessOrEqualThan'] = this.$moment().year(filter[field].value).endOf('year').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
              } else {
                filterParam[field + '.GreaterOrEqualThan'] = this.$moment().year(filter[field].value).startOf('year').format(this.$moment.HTML5_FMT.DATE)
                filterParam[field + '.LessOrEqualThan'] = this.$moment().year(filter[field].value).endOf('year').format(this.$moment.HTML5_FMT.DATE)
              }
            }
            break
          default:
            var values = filter[field].value
            for (var type in values) {
              if (values[type] || values[type] === 0) {
                filterParam[field + '.' + type] = values[type]
              }
            }
        }
      }

      return filterParam
    },
    processApiSearchValidationError (error) {
      if (error.response && error.response.data.fieldErrors) {
        error.response.data.fieldErrors.forEach(fieldError => {
          var vField = this.$validator.fields.find({ name: fieldError.field })
          if (!vField && fieldError.field.endsWith('.equals')) {
            vField = this.$validator.fields.find({ name: fieldError.field.replace(/.equals$/, '') })
          }
          if (!vField && fieldError.field.endsWith('.startWith')) {
            vField = this.$validator.fields.find({ name: fieldError.field.replace(/.startWith$/, '') })
          }

          if (vField) {
            vField.setFlags({ valid: false })
          }

          this.formErrors.add({
            field: vField ? vField.name : fieldError.field,
            msg: fieldError.message,
            scope: 'search'
          })
        })
      }
    },
    validateSearchForm (successCallback) {
      this.$refs.searchObserver.validate().then((valid) => {
        if (valid) {
          successCallback()
        } else {
          this.error = {
            typeCode: 'error.validation',
            fieldErrors: []
          }

          Object.keys(this.$refs.validObserver.errors).forEach(field => {
            this.error.fieldErrors.push({ field: field, message: this.$refs.validObserver.errors[field][0] })
          })
        }
      })
    },
    applyFilter () {
      var vm = this
      this.validateSearchForm(() => {
        vm.tableBusy = true
        vm.currentPage = 1
        vm.filterStr = JSON.stringify(vm.filter)
        vm.tableBusy = false
      })
    },
    resetFilter () {
      this.clearError()
      this.tableBusy = true
      var filter = this.filter
      for (var field in filter) {
        if (!filter[field].nonresettable) {
          switch (filter[field].fieldType) {
            case 'select' :
              filter[field].value = filter[field].default ? filter[field].default : null
              break
            case 'dateRange':
              filter[field].value = filter[field].default ? filter[field].default : null
              break
            case 'singleDate':
              filter[field].value = filter[field].default ? filter[field].default : null
              break
            case 'rok':
              filter[field].value = filter[field].default ? filter[field].default : null
              break
            default:
              filter[field].value = filter[field].default ? filter[field].default : {}
              break
          }
        }
      }
      this.sortBy = this.sortByInit
      this.sortDesc = this.sortDescInit
      this.$nextTick(function () {
        this.applyFilter()
      })
    },
    onError (error) {
      this.error = null
      // if (!error.status) {
      //  this.error = {message: this.$t('')}
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        this.processApiSearchValidationError(error)
        this.error = error.response.data
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        this.error = { message: this.$t('error.serverNotReachable') }
        console.log(error.message)
      } else {
        // Something happened in setting up the request that triggered an Error
        this.error = { message: this.$t('error.undefined') }
        Promise.reject(error)
      }
    },
    clearError () {
      this.error = null
    },
    onPage (data) {
    },
    /* applyFilter () {
      this.filterStr = JSON.stringify(this.filter)
    }, */
    getPromise (ctx) {
      var param = this.getFilterParam(JSON.parse(ctx.filter))

      param.page = ctx.currentPage - 1
      param.size = ctx.perPage
      param.sort = ctx.sortBy ? ctx.sortBy + ',' + (ctx.sortDesc ? 'desc' : 'asc') : ''

      return this.$http.get(ctx.apiUrl ? ctx.apiUrl : this.apiUrl, { params: param })
    },
    dataProvider (ctx) {
      this.clearError()

      this.$store.dispatch('table/setTableState', {
        name: this.$route.name,
        query: ctx
      })

      // var param = this.getFilterParam(this.filter)
      var param = this.getFilterParam(JSON.parse(ctx.filter))

      param.page = ctx.currentPage - 1
      param.size = ctx.perPage
      param.sort = ctx.sortBy ? ctx.sortBy + ',' + (ctx.sortDesc ? 'desc' : 'asc') : ''

      let promise = this.getPromise(ctx)

      return promise.then((response) => {
        let items = response.data.content
        this.perPage = response.data.size
        this.totalPages = response.data.totalPages
        this.totalRows = response.data.totalElements

        this.currentPage = response.data.number + 1

        if (response.data.sort && response.data.sort.lenght > 0) {
          this.sortBy = response.data.sort[0].property
          this.sortDesc = response.data.sort[0].descending
        }
        this.onPage(response.data)

        for (var prop in response.data.filters) {
          response.data.filters[prop] = response.data.filters[prop].sort()
        }

        if (response.data.subGroups && response.data.subGroups.lenght > 0) {
          this.allowedSubGroups = response.data.subGroups.sort((a, b) => (a.description > b.description) ? 1 : -1)
        }

        // this.$router.replace({query: {page: this.currentPage}})

        // Here we could override the busy state, setting isBusy to false
        // this.isBusy = false
        return (items || [])
      }).catch((error) => {
        this.onError(error)
        this.onPage(null)
        return []
      })
    },
    dataFilterProvider (ctx) {
      this.clearError()

      this.$store.dispatch('table/setTableState', {
        name: this.$route.name,
        query: ctx
      })

      // var param = this.getFilterParam(this.filter)
      var param = this.getFilterParam(JSON.parse(ctx.filter))

      param.page = ctx.currentPage - 1
      param.size = ctx.perPage
      param.sort = ctx.sortBy ? ctx.sortBy + ',' + (ctx.sortDesc ? 'desc' : 'asc') : ''

      let promise = this.getPromise(ctx)

      return promise.then((response) => {
        let items = response.data.content
        this.perPage = response.data.size
        this.totalPages = response.data.totalPages
        this.totalRows = response.data.totalElements

        this.currentPage = response.data.number + 1

        if (response.data.sort && response.data.sort.lenght > 0) {
          this.sortBy = response.data.sort[0].property
          this.sortDesc = response.data.sort[0].descending
        }
        this.onPage(response.data)

        for (var prop in response.data.filters) {
          response.data.filters[prop] = response.data.filters[prop].sort()
        }
        if (response.data.subGroups && response.data.subGroups.lenght > 0) {
          this.allowedSubGroups = response.data.subGroups.sort((a, b) => (a.description > b.description) ? 1 : -1)
        }
        // this.$router.replace({query: {page: this.currentPage}})

        // Here we could override the busy state, setting isBusy to false
        // this.isBusy = false
        return (response.data)
      }).catch((error) => {
        this.onError(error)
        this.onPage(null)
        return []
      })
    },
    async getDataToExport () {
      this.clearError()

      var param = this.getFilterParam(JSON.parse(this.filterStr))
      param.paged = false
      param.sort = this.sortBy ? this.sortBy + ',' + (this.sortDesc ? 'desc' : 'asc') : ''

      try {
        const response = await this.$http.get(this.apiUrl, { params: param })
        return response.data.content
      } catch (error) {
        this.onError(error)
      }
    }
  }
}
