<template>
  <b-container class="main">
  <div class="card">

      <div class="card-body">
        <div class="d-sm-flex">
          <h5 class="card-title flex-grow-1" v-t="'users.title'"/>

          <ValidationObserver ref="searchObserver" v-slot="{ }">
          <b-form class="" @submit.prevent="applyFilter" autocomplete="off">
            <b-row>
              <b-col>
                <b-form-input id="secondname"
                name="secondname"
                type="text"
                v-model="filter.secondname.value.startWith"
                :placeholder="$t('fields.secondname')">
                </b-form-input>
              </b-col>
              <b-col cols="4">
                <b-form-input id="email"
                name="email"
                type="text"
                v-model="filter.email.value.startWith"
                :placeholder="$t('fields.email')">
                </b-form-input>
              </b-col>
              <b-col class="align-self-center">
                <b-form-checkbox
                name="standard"
                type="text"
                :unchecked-value=null
                v-model="filter.blocked.value.equals">{{$t('user-status.blocked')}}</b-form-checkbox>
              </b-col>
              <b-col>
                <b-button class="mb-2 mr-1" variant="primary" type="submit"><font-awesome-icon icon="search"/></b-button>
              </b-col>
            </b-row>
          </b-form>
          </ValidationObserver>

        </div>
        <api-error :error="error"></api-error>

        <b-table ref="table" responsive="md" :small=true
             :items="dataProvider"
             :fields="fields"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :current-page="currentPage"
             :per-page="perPage"
             :filter="filterStr"
             api-url="data/user">

            <template v-slot:cell(secondname)="row">
              <b-link :to="{ name: 'user', params: { id: row.item.id }}" :title="$t('button.update.title')" class="text-dark">
                {{row.item.secondname}} {{row.item.firstname}}
              </b-link>
            </template>
            <template v-slot:cell(actions)="row">
              <b-link :to="{ name: 'user', params: { id: row.item.id }}" :title="$t('button.update.title')">
                <font-awesome-icon icon="edit" class="font-weight-bold" fixed-width aria-hidden="true"/>
              </b-link>
            </template>
            <template v-slot:cell(seePcl)="row">
              <font-awesome-icon v-if="row.item.seePcl" icon="check" fixed-width aria-hidden="true"/>
            </template>
            <template v-slot:cell(prices)="row">
              <font-awesome-icon v-if="row.item.prices" icon="check" fixed-width aria-hidden="true"/>
            </template>
            <template v-slot:cell(pricesComponent)="row">
              <font-awesome-icon v-if="row.item.pricesComponent" icon="check" fixed-width aria-hidden="true"/>
            </template>
            <template v-slot:cell(instanceGL)="row">
              <font-awesome-icon v-if="row.item.instance.includes('GL')" icon="check" fixed-width aria-hidden="true"/>
            </template>
            <template v-slot:cell(instanceRU)="row">
              <font-awesome-icon v-if="row.item.instance.includes('RU')" icon="check" fixed-width aria-hidden="true"/>
            </template>
            <template v-slot:cell(blocked)="row">
              <font-awesome-icon v-if="row.item.blocked" icon="check" fixed-width aria-hidden="true"/>
            </template>
        </b-table>
        <b-row>
            <b-col md="8" class="my-1">
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
            </b-col>
            <b-col md="4" class="my-1">
                <TableSize v-model="perPage"/>
            </b-col>
        </b-row>
      </div>
  </div>
  </b-container>
</template>

<script>
import TableSize from '@/components/TableSize.vue'
import tableMixin from '@/mixins/table'
import { ValidationObserver } from 'vee-validate'

export default {
  components: { TableSize, ValidationObserver },
  mixins: [tableMixin],
  data () {
    return {
      sortBy: 'secondname',
      filter: {
        blocked: { fieldType: 'input', value: { equals: false }, default: { equals: null } },
        email: { fieldType: 'input', value: {} },
        secondname: { fieldType: 'input', value: {} }
      },
      filterStr: JSON.stringify(this.filter)
    }
  },
  computed: {
    fields () {
      let fields = [
        { key: 'secondname', label: this.getColumnLabel('fullName'), sortable: true },
        { key: 'email', label: this.getColumnLabel('email'), sortable: true },
        { key: 'seePcl', label: this.getColumnLabel('seePcl'), sortable: false, class: 'text-center' },
        { key: 'prices', label: this.getColumnLabel('prices'), sortable: true, class: 'text-center' },
        { key: 'pricesComponent', label: this.getColumnLabel('pricesComponent'), sortable: true, class: 'text-center' },
        { key: 'instanceGL', label: 'GL', sortable: false },
        { key: 'instanceRU', label: 'RU', sortable: false },
        { key: 'blocked', label: this.$t('user-status.blocked'), sortable: true, class: 'text-center' },
        { key: 'lastlogindate', label: this.getColumnLabel('lastlogindate'), sortable: true, formatter: value => { return value ? this.$moment(value).format('L LT') : null } },
        { key: 'actions', label: '', class: 'text-right' }
      ]

      if (!this.$auth.hasRole('ROLE_ADMIN')) {
        fields = fields.filter(field => field.key !== 'instanceGL' && field.key !== 'instanceRU')
      }

      if (!this.$auth.hasAnyRole(['ROLE_ADMIN', 'ROLE_ADMIN_INST_GL'])) {
        fields = fields.filter(field => field.key !== 'seePcl' && field.key !== 'prices' && field.key !== 'pricesComponent')
      }

      return fields
    }
  }
}
</script>

<style>

</style>
