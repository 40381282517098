
export default {
  data () {
    return {
      error: null,
      formObject: {},
      saving: { value: false },
      deleting: { value: false },
      loading: { value: false }
    }
  },
  computed: {
    busy: {
      get: function () {
        return this.saving.value || this.deleting.value || this.loading.value
      }
    },
    /* Uwaga!! sprawdza tylko validowane pola (dyrektywa v-validate)  */
    formDirty: {
      get: function () {
        return Object.keys(this.formFields).some(key => this.formFields[key].dirty)
      }
    }
  },
  methods: {
    processApiValidationError (error) {
      if (error.response && error.response.data.fieldErrors) {
        var formErrors = {}
        error.response.data.fieldErrors.forEach(fieldError => {
          if (!formErrors.hasOwnProperty(fieldError.field)) formErrors[fieldError.field] = []
          formErrors[fieldError.field].push(this.$t(fieldError.message))
        })

        this.$refs.validObserver.setErrors(formErrors)
      }
    },
    processApiError (error) {
      this.error = null
      // if (!error.status) {
      //  this.error = {message: this.$t('')}
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        this.processApiValidationError(error)
        this.error = error.response.data
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        this.error = { message: this.$t('error.serverNotReachable') }
        console.log(error.message)
      } else {
        // Something happened in setting up the request that triggered an Error
        this.error = { message: this.$t('error.undefined') }
        Promise.reject(error)
      }
    },
    setData (data) {
      this.formObject = data
    },
    getData () {
      return this.formObject
    },
    submit (promise, flag, redirectRoute) {
      flag.value = true
      promise.then((response) => {
        flag.value = false
        this.setData(response.data)
        this.error = null
        if (redirectRoute && typeof redirectRoute === 'function') {
          this.$router.replace(redirectRoute())
        } else if (redirectRoute) {
          this.$router.replace(redirectRoute)
        }
      })
        .catch((error) => {
          flag.value = false
          this.processApiError(error)
        })
    },
    callPut (url, data, flag, redirectRoute) {
      this.submit(this.$http.put(url, data), flag, redirectRoute)
    },
    callDelete (url, flag, redirectRoute) {
      this.submit(this.$http.delete(url), flag, redirectRoute)
    },
    validateForm (successCallback) {
      var _vm = this
      this.$refs.validObserver.validate().then((valid) => {
        if (valid) {
          successCallback()
        } else {
          this.error = {
            typeCode: 'error.validation',
            fieldErrors: []
          }

          Object.keys(_vm.$refs.validObserver.errors).forEach(field => {
            if (_vm.$refs.validObserver.errors[field].length > 0) {
              _vm.error.fieldErrors.push({ field: field, message: _vm.$refs.validObserver.errors[field][0] })
            }
          })
        }
      })
    }
  }
}
