import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/NotFound.vue'
import AccessDenied from '../views/AccessDenied.vue'
import Home from '../views/Home.vue'
import Surowce from '../views/Surowce.vue'
import Surowiec from '../views/Surowiec.vue'
import Wyroby from '../views/Wyroby.vue'
import Wyrob from '../views/Wyrob.vue'
import Historia from '../views/Historia.vue'
import Pomoc from '../views/Pomoc.vue'
import Admin from '../views/admin/Admin.vue'
import UserList from '../views/admin/UserList.vue'
import User from '../views/admin/User.vue'
import WelcomeMessageList from '@/views/admin/WelcomeMessageList.vue'
import WelcomeMessage from '@/views/admin/WelcomeMessage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/surowce/:id',
    name: 'surowiec',
    component: Surowiec,
    meta: {
      requiresAuth: ['ROLE_USER']
    }
  },
  {
    path: '/surowce',
    name: 'surowce',
    component: Surowce,
    meta: {
      requiresAuth: ['ROLE_USER']
    }
  },
  {
    path: '/wyroby',
    name: 'wyroby',
    component: Wyroby,
    meta: {
      requiresAuth: ['ROLE_USER']
    },
    props: (route: { query: { itemCode: any } }) => ({ query: route.query.itemCode })
  },
  {
    path: '/wyroby/:wyrobId',
    name: 'wyrob',
    component: Wyrob,
    meta: {
      requiresAuth: ['ROLE_USER']
    }
  },
  {
    path: '/wyroby/:wyrobId/surowce/:id',
    name: 'surowiecWyrobu',
    component: Surowiec,
    meta: {
      requiresAuth: ['ROLE_USER']
    }
  },
  {
    path: '/historia',
    name: 'historia',
    component: Historia,
    meta: {
      requiresAuth: ['ROLE_USER']
    }
  },
  {
    path: '/pomoc',
    name: 'pomoc',
    component: Pomoc,
    meta: {
      requiresAuth: ['ROLE_USER']
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: ['ROLE_ADMIN', 'ROLE_ADMIN_INST_RU', 'ROLE_ADMIN_INST_GL']
    },
    children: [
      {
        path: 'users',
        name: 'users',
        component: UserList
      },
      {
        path: 'user/:id',
        name: 'user',
        component: User
      },
      {
        path: 'messages',
        name: 'messages',
        component: WelcomeMessageList
      },
      {
        path: 'message/:id',
        name: 'message',
        component: WelcomeMessage
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/403',
    component: AccessDenied
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

export default router
