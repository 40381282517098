import Toasted from 'vue-toasted'
import ToastedInterceptor from './toasted-interceptor'
import router from '../router'
import i18n from '../i18n'
import Vue from 'vue'

Vue.use(Toasted, {
  router: router,
  theme: 'toasted-primary',
  position: 'top-right',
  duration: 8000,
  iconPack: 'fontawesome'
})

Vue.use(ToastedInterceptor, {
  i18n: i18n,
  router: router
})
