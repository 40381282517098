<template>
<b-container class="main">
    <div class="card">
      <div class="card-header bg-white d-flex flex-wrap">
        <h4 class="flex-grow-1" v-if="this.formObject && this.formObject.itemCode">{{formObject.itemCode}}</h4>
        <h4 class="flex-grow-1" v-else>{{$t('surowce.title')}}</h4>
        <b-button class="mr-1" v-if="this.formObject && this.formObject.itemCode" variant="success"
            :title="$t('button.goToWyroby.label')" :to="goToWyroby">{{$t('button.goToWyroby.label')}}
        </b-button>
        <b-button class="mr-1" v-if="this.formObject" target="_blank" :href="getFullUrl('data/item/export?ids='+this.formObject.inventoryitemid)" variant="light" :title="$t('button.export.label')">{{$t('button.export.label')}}</b-button>
        <b-button class="align-self-start" variant="light" :to="redirectRoute" :title="$t('button.back.title')">
          <font-awesome-icon icon="reply" fixed-width/></b-button>
      </div>

      <div class="card-body">
        <p>{{formObject.itemdesc}}</p>
        <p>{{$t('fields.group')}}: {{formObject.catalogDescription}}</p>
        <h5>{{$t('surowce.characteristic')}}</h5>
        <span v-html="formObject.itemDescExt"></span>
        <api-error :error="error"></api-error>
        <b-table striped hover :items="formObject.properties" :fields="tableFields"></b-table>
        <div class="row">
          <div v-for="(src, index) in imagesWithUrl" :key="index" class="w-25 p-3" @click="() => showImg(index)">
            <b-img :src="src" thumbnail fluid></b-img>
          </div>
          <vue-easy-lightbox
          :visible="visible"
          :imgs="imagesWithUrl"
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
        </div>
      </div>
    </div>
    <!--<b-card v-for="iUrl in formObject.images" v-bind:key="iUrl"
      overlay
      :img-src="getFullUrl(iUrl)"
      text-variant="white"
      title=""
      sub-title=""
    >
    <b-card-text>
    </b-card-text>
  </b-card>-->
</b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { formMixin, beforeRouteEnter } from '@/mixins/formLazy'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: { VueEasyLightbox },
  mixins: [formMixin],
  data () {
    return {
      visible: false,
      index: 0,
      apiUrl: 'data/item/',
      tableFields: [ {
        key: 'elementname',
        label: ''
      },
      {
        key: 'elementvalue',
        label: ''
      } ]
    }
  },
  computed: {
    ...mapGetters({
      currentLang: 'settings/currentLang'
    }),
    redirectRoute: {
      get: function () {
        if (this.$route.params.wyrobId) {
          return { name: 'wyrob' }
        } else {
          return { name: 'surowce' }
        }
      }
    },
    imagesWithUrl () {
      return (typeof this.formObject.images !== 'undefined' && this.formObject.images instanceof Array) ? this.formObject.images.map(x => this.axios.defaults.baseURL + x + '/' + this.formObject.itemCode) : []
    },
    goToWyroby: function () {
      return '/wyroby?itemCode=' + this.formObject.itemCode
    }
  },
  beforeRouteEnter (to, from, next) {
    beforeRouteEnter(to, from, next, 'data/item/', to.params.id)
  },
  methods: {
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    getFullUrl: function (tmpUrl) {
      return this.axios.defaults.baseURL + tmpUrl
    }
  }
}
</script>

<style>

</style>
