<template>
  <b-navbar toggleable="md" type="dark" fixed="top" class="pt-0 pb-0" variant="primary">

    <b-navbar-brand  class="pt-0 pb-0" :to="{ path: '/'}">
      <img :src="require('../assets/Matopat-logo-small.png')" height="52"
          class="d-inline-block pt-2 pb-2" alt="" >
    </b-navbar-brand>

    <b-navbar-toggle target="nav_collapse" class="ml-auto"></b-navbar-toggle>

    <b-collapse is-nav id="nav_collapse">

      <b-navbar-nav class="mr-auto">
        <b-nav-item active-class="btn btn-info active" :to="{ path: '/surowce'}" v-if="$auth.hasRole('ROLE_USER')">{{$t('menu.surowce')}}</b-nav-item>
        <b-nav-item active-class="btn btn-info active" :to="{ path: '/wyroby'}" v-if="$auth.hasRole('ROLE_USER')">{{$t('menu.wyroby')}}</b-nav-item>
        <b-nav-item active-class="btn btn-info active" :to="{ path: '/historia'}" v-if="$auth.hasRole('ROLE_USER')">{{$t('menu.historia')}}</b-nav-item>
        <b-nav-item active-class="btn btn-info active" :to="{ path: '/pomoc'}" v-if="$auth.hasRole('ROLE_USER')">{{$t('menu.pomoc')}}</b-nav-item>

        <b-nav-item active-class="btn btn-info active" :to="{ path: '/admin'}"
          v-if="$auth.hasAnyRole(['ROLE_ADMIN', 'ROLE_ADMIN_INST_RU', 'ROLE_ADMIN_INST_GL'])">
          {{$t('menu.administracja')}}
        </b-nav-item>

      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item-dropdown v-if="$auth.isAuthenticated()" :text="$t('menu.instance')" right>
          <b-dropdown-item-button active-class='font-weight-bolder'
                v-for='(name, value) in allowedInstances'
                :key='value'
                :active="currentInstance === value"
                @click='changeInstance(value)'>
            <font-awesome-icon v-if="currentInstance === value" icon='check' fixed-width size="sm"></font-awesome-icon>
            <i v-else class="fa-fw d-inline-block"></i> {{name}}
          </b-dropdown-item-button>

        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <template slot="button-content">
            <font-awesome-icon icon="flag" size="lg" class="d-none d-md-inline-block"/>
            <div class="d-inline-block d-md-none">Język</div>
          </template>

          <b-dropdown-item-button active-class='font-weight-bolder'
                v-for='(name,value) in languages'
                :key='value'
                :active="currentLang === value"
                @click='changeLanguage(value)'>
            <font-awesome-icon v-if="currentLang === value" icon='check' fixed-width size="sm"></font-awesome-icon>
            <i v-else class="fa-fw d-inline-block"></i> {{name}}
          </b-dropdown-item-button>

        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav>

        <b-nav-item-dropdown right v-if="$auth.isAuthenticated()" class="pt-0 pb-0 d-none d-md-block" no-caret >
          <template slot="button-content">
              <b-img :src="user.imageUrl" width="42" height="42" class="pt-0 pb-0 mb-n1 mt-n1" alt="" rounded="circle"/>
          </template>
          <b-dropdown-item-button @click='logout'>{{$t('menu.logout')}}</b-dropdown-item-button>
        </b-nav-item-dropdown>

        <b-nav-item @click='logout' class="d-md-none" v-if="$auth.isAuthenticated()">{{$t('menu.logout')}}</b-nav-item>

        <b-navbar-nav v-if="!$auth.isAuthenticated()" >
            <b-nav-item :title="$t('menu.login')" :href="axios.defaults.baseURL + 'oauth2/authorization/google'" >
                  {{$t('menu.login')}} <font-awesome-icon icon="sign-in-alt" size="lg"></font-awesome-icon>
            </b-nav-item>
        </b-navbar-nav>
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>

</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentLang: 'settings/currentLang',
      languages: 'settings/languages',
      currentInstance: 'settings/currentInstance',
      instances: 'settings/instances'
    }),
    ...mapState({
      user: state => state.account.user.data,
      auth: state => state.account.user.isAuthenticated
    }),
    allowedInstances () {
      var result = {}
      var col = this.instances
      for (var name in col) {
        if (name === 'GL' && (this.$auth.hasRole('ROLE_INST_GL') || this.$auth.hasRole('ROLE_ADMIN'))) {
          result[name] = this.instances.GL
        }
        if (name === 'RU' && (this.$auth.hasRole('ROLE_INST_RU') || this.$auth.hasRole('ROLE_ADMIN'))) {
          result[name] = this.instances.RU
        }
      }
      return result
    }
  },
  mounted () {
    this.setInstance()
  },
  watch: {
    auth (newVal, oldVal) {
      if (newVal) {
        this.setInstance()
      }
    }
  },
  methods: {
    changeLanguage (lang) {
      this.$i18n.locale = lang
      this.$moment.locale(lang)
      this.$store.commit('settings/currentLang', lang)
      this.$router.push('/')
    },
    changeInstance (i) {
      this.$store.commit('settings/currentInstance', i)
    },
    logout () {
      this.$auth.logout()
      this.$router.push('/')
    },
    setInstance () {
      if (this.$auth.hasRole('ROLE_INST_GL') && this.currentInstance !== 'GL') {
        this.changeInstance('GL')
      } else if (this.$auth.hasRole('ROLE_INST_RU') && this.currentInstance !== 'RU') {
        this.changeInstance('RU')
      }
    }
  }
}
</script>
