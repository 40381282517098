<template>
  <b-container class="main">
    <b-jumbotron>
      <template slot="header">
        <font-awesome-icon icon="minus-circle" class="text-danger mr-4"></font-awesome-icon>&nbsp;&nbsp;<strong></strong> {{$t('403.title')}}
      </template>
      <p class="lead" v-html="$t('403.detail')"></p>
      <hr class="my-4">
      <p>{{$t('4xx.info')}}</p>
      <div>
        <b-btn variant="primary" @click="goBack" class="mr-1"><font-awesome-icon icon="chevron-left" class="fa-fw" aria-hidden="true"></font-awesome-icon>&nbsp;{{$t('4xx.btnBackTitle')}}</b-btn>
        <b-btn variant="success" :to="{ path: '/'}"><font-awesome-icon icon="home" class="fa-fw" aria-hidden="true"></font-awesome-icon>&nbsp;{{$t('4xx.btnHomeTitle')}}</b-btn>
      </div>
    </b-jumbotron>
  </b-container>
</template>

<script>
export default {
  methods: {
    goBack (event) {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/')
    }
  }
}
</script>

<style>

</style>
