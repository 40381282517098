<template>
  <b-row align-v="center">
    <b-col cols="4"><label for="pageSize">{{showLabel?$t('tableSize.pageSize'):''}}</label></b-col>
    <b-col>
    <b-form-select
      id="pageSize"
      :options="pageOptionsTranslated"
      v-bind:value="value"
      v-on:change="$emit('change', $event)"
      />
    </b-col>
    <b-col cols="3">
    <h5><b-badge variant="info">{{$t('tableSize.total')}}: {{totalRows}}</b-badge></h5>
    </b-col>
  </b-row>
</template>

<script>
import { integer } from 'vee-validate/dist/rules'
export default {
  name: 'TableSize',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    totalRows: {
      type: Number,
      default: 0
    },
    pageOptions: {
      type: Array,
      default: () => [
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '200', value: 200 },
        { text: 'Wszystko', value: 9999 }
      ]
    }
  },
  computed: {
    pageOptionsTranslated () {
      return this.pageOptions.map(x => {
        return { ...x, text: this.$t('table.' + x.text) }
      })
    }
  }
}
</script>
