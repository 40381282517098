import Vue from 'vue'
import { Module } from 'Vuex'

var VueCookie = require('vue-cookie')

const mSettings: Module<any, any> = {
  namespaced: true,
  state: {
    currentLang: (Vue as any).cookie.get('LANG_KEY') || 'pl',
    currentInstance: (Vue as any).cookie.get('INSTANCE') || 'GL',
    defaultCatalog: JSON.parse(localStorage.getItem('DefaultCatalog') || '{ "pl": null, "en-US":null, "ru":null}'),
    languages: {
      'en-US': 'English',
      'pl': 'Polski',
      'ru': 'Русский'
    },
    instances: {
      'GL': 'Global',
      'RU': 'Russia'
    }
  },
  mutations: {
    currentLang (state, newLanguage) {
      (Vue as any).cookie.set('LANG_KEY', newLanguage, { expires: 365 })
      state.currentLang = newLanguage
    },
    currentInstance (state, newInstance) {
      (Vue as any).cookie.set('INSTANCE', newInstance, { expires: 365 })
      state.currentInstance = newInstance
    },
    defaultCatalog (state, newCatalog) {
      state.defaultCatalog[state.currentLang] = newCatalog
      localStorage.setItem('DefaultCatalog', JSON.stringify(state.defaultCatalog))
    }
  },
  getters: {
    currentLang: state => state.currentLang,
    currentInstance: state => state.currentInstance,
    languages: state => state.languages,
    instances: state => state.instances,
    defaultCatalog: state => state.defaultCatalog[state.currentLang]
  }
}

export default mSettings
