<template>
  <div id="app">
    <app-nav/>
    <router-view/>
  </div>
</template>

<script>
import AppNav from './components/AppNav.vue'

export default {
  components: { AppNav }
}
</script>

<style>
.main{
  padding-top: 25px;
}

body {
  font-size: .9375rem;
}

#app {
  padding-top: 3.5rem;
}

#app > nav {
  min-height: 3rem
}
</style>
