<template>
<b-container class="main">
    <div class="card">
      <div class="card-header bg-white d-flex flex-wrap">
        <h4 class="flex-grow-1">
          {{formObject && formObject.itemCode ?formObject.itemCode: 'Wyrób'}}
        </h4>
        <b-button class="align-self-start" variant="light" :to="redirectRoute" :title="$t('button.back.title')">
          <font-awesome-icon icon="reply" fixed-width/></b-button>
      </div>
      <div class="row no-gutters" v-if="this.formObject">
        <div class="col-md-6 order-2 order-md-1">
          <hr class="d-block d-md-none mb-0 mt-0"/>
          <div class="card-body">
            <p class="card-text">{{formObject.itemDesc}}</p>
            <p class="card-text text-muted">{{formObject.itemDescLong}}</p>
            <p class="card-text text-muted" v-if="$auth.hasAnyRole(['ROLE_SEE_NIEROT'])">{{$t('fields.nierotKategoria')}}: {{formObject.nierotKategoria}}</p>
            <p class="card-text text-muted">{{$t('fields.class')}}: {{formObject.klasa}}</p>
            <p class="card-text text-muted">{{$t('wyroby.validFrom')}}: {{this.$moment(formObject.lastUpdateDate).format('L LT')}}</p>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2" v-if="this.formObject.inventoryitemid">
          <b-img :src="axios.defaults.baseURL + apiUrl + formObject.inventoryitemid + '/image'" fluid></b-img>
        </div>
      </div>
      <div class="card-body" v-if="this.formObject">
        <wyrob-surowce-list :inventoryItemId="this.formObject.inventoryitemid"></wyrob-surowce-list>
      </div>
    </div>
</b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { formMixin, beforeRouteEnter } from '@/mixins/formLazy'
import WyrobSurowceList from '@/components/WyrobSurowceList.vue'

export default {
  mixins: [formMixin],
  components: { WyrobSurowceList },
  data () {
    return {
      objectIdProp: 'wyrobId',
      apiUrl: 'data/itemSet/'
    }
  },
  computed: {
    ...mapGetters({
      currentLang: 'settings/currentLang'
    }),
    redirectRoute: {
      get: function () {
        return { name: 'wyroby' }
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    beforeRouteEnter(to, from, next, 'data/itemSet/', to.params.wyrobId)
  },
  methods: {
    getFullUrl: function (tmpUrl) {
      return this.axios.defaults.baseURL + tmpUrl
    }
  }
}
</script>

<style>

</style>
