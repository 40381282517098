import _Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { Store } from 'vuex'
import account from './account'
// import qs from 'qs'

const SpringAuth = {
  install (Vue: typeof _Vue, options: SpringAuthPluginOptions) {
    options.store.registerModule('account', account)

    const checkRoles = (to: Route) => {
      var userAuth = options.store.state.account.user.data.authorities
      var authList = to.matched.map(record => record.meta.requiresAuth).filter(record => record ? record.constructor === Array : false)

      for (let auth of authList) {
        if (!auth.some((x: any) => userAuth.includes(x))) {
          return '/403'
        }
      }
      return null
    }

    options.router.beforeEach((to: Route, from: Route, next: Function) => {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!options.store.state.account.user.isAuthenticated) {
          next({
            path: '/'
            // query: { redirect: to.fullPath }
          })
          return
        }

        if (options.store.state.account.user.data === undefined ||
              options.store.state.account.user.data == null) {
          Vue.axios.get(options.userUrl)
            .then((response) => {
              options.store.dispatch('account/setUserData', { user: response.data })
              var redirect = checkRoles(to)
              if (redirect) next(redirect)
              else next()
            })
            .catch((error) => {
              console.log(error)
              next(error)
            })
        } else {
          var redirect = checkRoles(to)
          if (redirect) {
            next(redirect)
          } else next()
        }
      } else {
        next() // make sure to always call next()!
      }
    })

    Vue.axios.interceptors.response.use(function (response) {
      // Do something with response data
      return response
    }, function (error) {
      // Do something with response error
      if (error.response && error.response.status === 401 && options.router.currentRoute.name !== 'Login' &&
              options.router.currentRoute.name !== 'PasswordReset') {
        options.store.dispatch('account/logout')

        if (options.router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
          options.router.push({
            name: 'home'
            // query: { redirect: options.router.currentRoute.path }
          })
        }
      }
      return Promise.reject(error)
    })

    Vue.prototype.$auth = {}
    Vue.prototype.$auth.hasRole = function (name: string) {
      return options.store.state.account.user &&
                options.store.state.account.user.isAuthenticated &&
                options.store.state.account.user.data &&
                options.store.state.account.user.data.authorities &&
                options.store.state.account.user.data.authorities.includes(name)
    }
    Vue.prototype.$auth.hasAnyRole = function (names: any) {
      return options.store.state.account.user &&
                options.store.state.account.user.isAuthenticated &&
                options.store.state.account.user.data &&
                options.store.state.account.user.data.authorities &&
                options.store.state.account.user.data.authorities.some((x: any) => names.includes(x))
    }
    Vue.prototype.$auth.isAuthenticated = function () {
      return options.store.state.account.user.isAuthenticated
    }
    Vue.prototype.$auth.getUserName = function () {
      return options.store.state.account.user.data ? options.store.state.account.user.data.username : ''
    }
    Vue.prototype.$auth.getUserFullName = function () {
      return options.store.state.account.user.data ? options.store.state.account.user.data.fullName : ''
    }

    Vue.prototype.$auth.refresh = function () {
      Vue.axios.get(options.userUrl)
        .then((response) => {
          options.store.dispatch('account/login', { user: response.data })
        })
    }

    Vue.prototype.$auth.logout = function () {
      var result = Vue.axios.post(options.logoutUrl)
        .then((response) => {
          options.store.dispatch('account/logout')
          // to get a new csrf token call the api
          Vue.axios.get(options.userUrl)
          return Promise.resolve(response)
        })
      return result
    }
  }
}

export class SpringAuthPluginOptions {
  router!: VueRouter;
  store!: any;
  userUrl!: string;
  logoutUrl!: string
}

export class SpringAuthPlugin {

}

export default SpringAuth
