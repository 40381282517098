<template>
  <div class="col-4">
  <label>{{elementName}}</label>
    <multiselect
      :options="elementValues"
      :loading="isLoading"
      :multiple="true"
      :searchable="true"
      :allow-empty="true"
      :close-on-select="false"
      label="elementvalue"
      track-by="elementvalue"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      v-on:open="getElementValues"
    ></multiselect>
  </div>
</template>

<script>
export default {
  name: 'SearchFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    elementName: {
      type: String,
      required: true
    },
    catalogGroupId: {
      type: Number,
      required: true
    },
    value: {
      default: null
    },
    allowedValues: {
      type: Array,
      required: false,
      default: function () { return [] }
    }
  },
  data () {
    return {
      elementValues: [],
      isLoading: false
    }
  },
  methods: {
    getElementValues (a) {
      if (this.allowedValues.length >= 1) {
        this.elementValues = this.allowedValues.map((v, i) => ({ elementvalue: v }))
        return
      }
      if (this.elementValues.length > 1) {
        return
      }
      this.isLoading = true
      this.$http.get('data/catalogElementValues', {
        params: {
          catalogGroupId: this.catalogGroupId,
          elementName: this.elementName
        }
      })
        .then((response) => {
          this.elementValues = response.data
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
        })
    }
  }
}
</script>
