import axios from '@/plugins/axios-vue'
import baseFormMixin from './baseForm'

function beforeRouteEnter (to, from, next, apiUrl, id) {
  if (id === 'add') {
    next(/* vm => vm.setData(null, {}) */)
  } else {
    axios.get(apiUrl + id)
      .then((response) => {
        next(vm => {
          vm.setData(response.data)
          vm.error = null
        })
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error)
          next('/404')
        } else {
          next(vm => {
            vm.setData(null)
            vm.processApiError(error)
          })
        }
      })
  }
}

var formMixin = {
  extends: baseFormMixin,
  data () {
    return {
      apiUrl: '',
      objectIdProp: 'id'
    }
  },
  /* beforeRouteEnter (to, from, next) {
    var objectId = to.meta.objectId
    if (!objectId) objectId = 'id'
    beforeRouteEnter(to, from, next, to.meta.apiUrl, objectId)
  }, */
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    if (to.params[this.objectIdProp] !== from.params[this.objectIdProp]) {
      this.setData(null)
      this.$http.get(this.getApiUrl(to.params) + to.params[this.objectIdProp])
        .then((response) => {
          this.setData(response.data)
          this.error = null
          next()
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            next('/404')
          } else {
            this.setData(null)
            this.processApiError(error)
            next()
          }
        })
    } else {
      next()
    }
  },
  methods: {
    postData (url, data) {
      this.callPut(url, data, this.saving, this.redirectRoute)
    },
    onSubmit () {
      var vm = this
      this.validateForm(function () { vm.postData(vm.getApiUrl(vm.$route.params), vm.getData()) })
    },
    onDelete () {
      this.callDelete(this.getApiUrl(this.$route.params) + this.formObject.id, this.deleting, this.redirectRoute)
    },
    getApiUrl () {
      return this.apiUrl
    },
    isValid (name) {
      return this.formFields[name] && this.formFields[name].valid
    }
  }
}

export { formMixin, beforeRouteEnter }
