<template>
    <b-container fluid class="main bg-white">
      <b-form @submit.prevent="localApplyFilter">
        <div class="d-flex flex-wrap border-bottom stickySearch">
        <h2 v-t="'menu.surowce'" class="flex-grow-1"/>
        <div>
          <b-button-group class="mr-2">
            <b-button variant="primary" type="submit" size="sm" v-t="'button.search.label'"></b-button>
            <b-button variant="outline-secondary" size="sm" @click="resetFilterAndCatalog" v-t="'button.clear.label'"></b-button>
          </b-button-group>
          <b-button class="mr-2"
            size="sm"
            variant="outline-secondary"
            :aria-expanded="showFilter ? 'true' : 'false'"
            aria-controls="collapse-filter"
            @click="showFilter = !showFilter">
            <span v-if="showFilter"><font-awesome-icon icon="eye-slash" fixed-width/></span>
            <span v-else><font-awesome-icon icon="eye" fixed-width/></span>
          </b-button>
          <b-dropdown size="sm" variant="outline-secondary" no-caret right>
            <template v-slot:button-content>
              <font-awesome-icon icon="bars" fixed-width/>
            </template>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    :href="axios.defaults.baseURL + 'data/item/export?ids=' + selectedIds.join(',')">{{$t('button.export.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1|| selectedIds.length > 5 || selectedGroups.size > 1"
                    :href="axios.defaults.baseURL + 'data/item/compare?ids=' + selectedIds.join(',')">{{$t('button.compare.label')}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-form-row>
        <b-form-group :label="$t('fields.group')" for="grupa" class="col">
          <catalog-group-select
            id="grupa"
            v-model="filter.itemCatalogGroupId.value"
            :placeholder="$t('fields.group')"
            :allow-empty="true"
            v-on:input="getElements">
          </catalog-group-select>
        </b-form-group>
        <b-col>
          <label for="indeksOpis">{{$t('fields.itemCode')}} / {{$t('fields.itemDesc')}}</label>
          <b-form-input id="itemCode"
              v-model="filter.itemCode.value.equals">
          </b-form-input>
        </b-col>
        <b-col>
          <label for="assignment">{{$t('fields.assignment')}}</label>
          <b-form-input id="assignment"
              v-model="filter.assignment.value.equals">
          </b-form-input>
        </b-col>
        <b-col class="align-self-center">
          <b-form-checkbox
            name="added"
            type="text"
            :unchecked-value=null
            v-model="filter.added.value.equals">
            {{$t('fields.added')}}
          </b-form-checkbox>
          <b-form-checkbox
            name="standard"
            type="text"
            :unchecked-value=null
            v-model="filter.standard.value.equals">
            {{$t('fields.standard')}}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-row>
        <b-col>
          <div>
            <b-collapse id="collapse-4" v-model="showFilter" class="mt-2">
              <div class="row">
                  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                  <search-filter v-for="(value, name) in filter" :key="name" v-if="value.custom"
                    :elementName="value.label"
                    :catalogGroupId="filter.itemCatalogGroupId.value ? filter.itemCatalogGroupId.value.itemcataloggroupid: 0"
                    v-model="filter[name].value"
                    :allowedValues="getAllowedValues(value.label)"
                    />
                  <!-- eslint-enable vue/no-use-v-if-with-v-for -->
              </div>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
      </b-form>
      <api-error :error="error"></api-error>
      <ValidationObserver ref="searchObserver" v-slot="{ }"></ValidationObserver>
      <b-table
        selectable
        select-mode="multi"
        responsive="md"
        :small=true
        :items="localDataProvider"
        :fields="itemFields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filterStr"
        sticky-header="500px"
        @row-selected="onRowSelected"
        api-url="data/item">
        <template v-slot:cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <font-awesome-icon :icon="['far', 'check-square']" fixed-width/>
            </template>
            <template v-else>
              <font-awesome-icon :icon="['far', 'square']" fixed-width/>
            </template>
          </template>

          <template v-slot:head(selected)="{ selectAllRows, clearSelected }">
            <b-link :title="$t('button.selectAll.title')" class="text-dark" v-if="selectToggle" @click="() => { selectAllRows(); selectToggle=!selectToggle }">
              <font-awesome-icon :icon="['far', 'square']" fixed-width/>
            </b-link>
            <b-link :title="$t('button.deselectAll.title')" class="text-dark" v-if="!selectToggle" @click="() => { clearSelected(); selectToggle=!selectToggle }">
              <font-awesome-icon :icon="['far', 'check-square']" fixed-width/>
            </b-link>
          </template>
        <template v-slot:cell(itemCode)="row">
          <b-link :to="{ name: 'surowiec', params: { id: row.item.inventoryitemid }}" :title="$t('button.view.title')" class="text-dark">
            {{row.item.itemCode}}
          </b-link>
        </template>
        <template v-slot:cell(itemDesc)="row">
          <b-link :to="{ name: 'surowiec', params: { id: row.item.inventoryitemid }}" :title="$t('button.view.title')" class="text-dark">
            {{row.item.itemDesc}}
          </b-link>
        </template>
        <template v-slot:cell(added)="row">
          <font-awesome-icon v-if="row.item.added" icon="check" fixed-width aria-hidden="true"/>
        </template>
        <template v-slot:cell(standard)="row">
          <font-awesome-icon v-if="row.item.standard" icon="check" fixed-width aria-hidden="true"/>
        </template>
      </b-table>
        <b-row>
            <b-col md="8" class="my-1">
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
            </b-col>
            <b-col md="4" class="my-1">
                <TableSize v-model="perPage" :totalRows="totalRows"/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TableSize from '@/components/TableSize.vue'
import SearchFilter from '../components/SearchFilter'
import CatalogGroupSelect from '../components/CatalogGroupSelect'
import tableMixin from '@/mixins/table'
import { ValidationObserver } from 'vee-validate'

export default {
  components: { SearchFilter, CatalogGroupSelect, TableSize, ValidationObserver },
  mixins: [tableMixin],
  data () {
    return {
      canLoadData: false,
      selectToggle: true,
      selectedIds: [],
      selectedGroups: [],
      lang: [],
      showFilter: true,
      isBusyTable: false,
      catalogGroups: [],
      elements: [],
      items: [],
      sortBy: 'itemCode',
      filter: {
        itemCatalogGroupId: { fieldType: 'select', value: null, key: 'itemcataloggroupid', nonresettable: true },
        itemCode: { fieldType: 'input', value: {} },
        added: { fieldType: 'input', value: { equals: false }, default: { equals: null } },
        assignment: { fieldType: 'input', value: {} },
        standard: { fieldType: 'input', value: { equals: false }, default: { equals: null } }
      },
      filterStr: null,
      allowedFilterValues: {}
    }
  },
  computed: {
    customFilters: function () {
      return Object.keys(this.filter)
    },
    itemFields () {
      var vm = this
      var fields = [
        { key: 'selected', label: '' },
        { key: 'itemCode', label: this.$t('fields.itemCode'), sortable: true, tdClass: 'tdMinItemWidth' },
        { key: 'itemDesc', label: this.$t('fields.itemDesc'), sortable: true },
        { key: 'catalogDescription', label: this.$t('fields.group'), sortable: true },
        { key: 'assignment', label: this.$t('fields.assignment'), sortable: true },
        { key: 'added', label: this.$t('fields.added'), sortable: true },
        { key: 'standard', label: this.$t('fields.standard'), sortable: true }
      ]

      if (this.$auth.hasAnyRole(['ROLE_PRICES_CMP'])) {
        fields.push({ key: 'price',
          label: this.getColumnLabel('price'),
          sortable: true,
          class: 'text-right',
          formatter: value => { return value || value === 0 ? this.$n(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '' }
        })
      }

      return fields
    }
  },
  created () {
    if (this.$store.getters['table/getTable'](this.$route.name)) {
      this.canLoadData = true
    }
  },
  methods: {
    getAllowedValues (name) {
      return this.allowedFilterValues[name]
    },
    localApplyFilter () {
      this.canLoadData = true
      return this.applyFilter()
    },
    async localDataProvider (ctx) {
      if (!this.canLoadData && this.filter.itemCatalogGroupId.value == null) {
        this.filter.itemCatalogGroupId.value = 999999 // na start nic nie pokazujemy, ale musi być możliwość wyświeetlenia wszystkiego. Ot taki hot fix
        return []
      } else {
        let response = await this.dataFilterProvider(ctx)
        this.allowedFilterValues = Object.assign({}, response.filters)
        return response.content || []
      }
    },
    resetFilterAndCatalog () {
      this.filter.itemCatalogGroupId.value = null
      this.filter.added.value.equals = null
      this.filter.standard.value.equals = null
      this.getElements()
    },
    onRowSelected (items) {
      this.selectedIds = items.map(x => x.inventoryitemid)
      this.selectedGroups = new Set(items.map(x => x.itemcataloggroupid))
    },
    getElements (selectedOption, id) {
      var vm = this

      this.canLoadData = false
      this.resetFilter()

      for (var field in vm.filter) {
        if (vm.filter[field].custom) {
          vm.$delete(vm.filter, field)
        }
      }

      if (selectedOption && selectedOption.itemcataloggroupid) {
        vm.$http.get('data/catalogElements', {
          params: {
            catalogGroupId: selectedOption.itemcataloggroupid
          }
        })
          .then((response) => {
            response.data.forEach(element => {
              vm.$set(vm.filter, element.elementname, { fieldType: 'select', value: null, key: 'elementvalue', custom: true, label: element.elementname })
            })
            this.elements = response.data
          })
          .catch((error) => {
            console.log(error.response.data)
          })
      }
    },
    getFilterParam (filter) {
      var filterParam = {}

      for (var field in filter) {
        switch (filter[field].fieldType) {
          case 'select' :
            if (filter[field].value) {
              var key = filter[field].key ? filter[field].key : 'id'
              if (filter[field].value.constructor === Array) {
                if (filter[field].value.length > 0) {
                  filterParam[field] = {}
                  filterParam[field]['in'] = filter[field].value.map(x => x[key])
                }
              } else if (filter[field].value[key]) {
                filterParam[field] = {}
                filterParam[field]['equals'] = filter[field].value[key]
              }
            }
            break
          default:
            var values = filter[field].value
            for (var type in values) {
              if (values[type] || values[type] === 0) {
                filterParam[field] = {}
                filterParam[field][type] = values[type]
              }
            }
        }
      }

      return filterParam
    },
    getPromise (ctx) {
      var param = {}

      param.page = ctx.currentPage - 1
      param.size = ctx.perPage
      param.sort = ctx.sortBy ? ctx.sortBy + ',' + (ctx.sortDesc ? 'desc' : 'asc') : ''

      return this.$http.request({
        url: ctx.apiUrl ? ctx.apiUrl : this.apiUrl,
        method: 'post',
        params: param,
        data: this.getFilterParam(JSON.parse(ctx.filter))
      })
    }
  }
}
</script>

<style>

</style>
