<template>
<b-container class="main mt-3">
  <b-alert v-if="$auth.isAuthenticated() && !$auth.hasRole('ROLE_USER') && !$auth.hasRole('ROLE_ADMIN') && welcomeMessage" show variant="danger">
    <vue-simplemde v-if="welcomeMessage"
      :configs="markdownConfig"
      :value="welcomeMessage.content"
      ref="markdownEditor">
    </vue-simplemde>
  </b-alert>
</b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'home',
  data () {
    return {
      welcomeMessage: null,
      markdownConfig: {
        status: false,
        toolbar: false,
        spellChecker: false,
        autoDownloadFontAwesome: false
      }
    }
  },
  computed: {
    ...mapGetters({
      currentInstance: 'settings/currentInstance'
    }),
    ...mapState({
      auth: state => state.account.user.isAuthenticated
    })
  },
  mounted () {
    if (this.$auth.isAuthenticated() && !this.$auth.hasRole('ROLE_USER') && !this.$auth.hasRole('ROLE_ADMIN')) {
      this.fetchData()
    }
  },
  watch: {
    auth (newVal, oldVal) {
      if (newVal && !this.$auth.hasRole('ROLE_USER') && !this.$auth.hasRole('ROLE_ADMIN')) {
        this.fetchData()
      }
    }
  },
  methods: {
    async fetchData () {
      try {
        const response = await this.$http.get('data/welcome-message/active', { params: { instance: this.currentInstance } })
        this.welcomeMessage = response.data

        if (this.welcomeMessage) {
          this.$nextTick(() => {
            this.$refs.markdownEditor.simplemde.togglePreview()
          })
        }
      } catch (err) {
        throw err
      }
    }
  }
}
</script>

<style>
.vue-simplemde .CodeMirror, .vue-simplemde .CodeMirror-scroll {
  min-height: 50px;
  border-width: 0px;
}

.editor-preview, .editor-preview-side {
  background-color: #F8D7DA !important;
}
</style>
