<template>
  <b-container fluid class="main bg-white">

    <ValidationObserver ref="searchObserver" v-slot="{ }">
    <b-form @submit.prevent="applyFilter">
      <div class="d-flex flex-wrap border-bottom">
        <h2 v-t="'historia.title'" class="flex-grow-1"/>
        <div>
          <b-button-group class="mr-2">
            <b-button variant="primary" type="submit" size="sm" v-t="'button.search.label'"></b-button>
            <b-button variant="outline-secondary" size="sm" @click="resetFilter" v-t="'button.clear.label'"></b-button>
          </b-button-group>
          <b-button
            size="sm"
            variant="outline-secondary"
            :aria-expanded="showFilter ? 'true' : 'false'"
            aria-controls="collapse-filter"
            @click="showFilter = !showFilter"
            v-t="'button.filter.label'">
          </b-button>
        </div>
      </div>

      <api-error :error="error"></api-error>

      <b-collapse id="collapse-filter" v-model="showFilter">
        <b-form-row class="mt-2" >
          <b-form-group :label="$t('fields.timeOfCreation')" class="col-md-6 col-xl-4">
          <date-range-picker v-model='filter.timeOfCreation.value'/>
        </b-form-group>
          <b-form-group :label="$t('fields.itemCode')" for="itemCode" class="col-md-6 col-xl-4">
            <b-form-input id="itemCode"
                :placeholder="$t('fields.itemCode')"
                v-model="filter.itemCode.value.startWith">
            </b-form-input>
          </b-form-group>
          <b-form-group>
          <b-form-radio-group
            v-model="filter.product.value.equals"
            :options="sourceOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
            stacked
          ></b-form-radio-group>
          </b-form-group>
        </b-form-row>

      </b-collapse>
    </b-form>
    </ValidationObserver>

    <b-table ref="table" responsive="md" :small=true
          :items="dataProvider"
          :fields="fields"
          :sort-by.sync="sortBy"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filterStr"
          api-url="data/change">
        <template v-slot:cell(itemCode)="row">
          <b-link v-if="row.item.product == 0" :to="{ name: 'surowiec', params: { id: row.item.inventoryitemid }}" title="Szczegóły">
            {{row.item.itemCode}}
          </b-link>
          <b-link v-else :to="{ name: 'wyrob', params: { wyrobId: row.item.inventoryitemid }}" title="Szczegóły">
            {{row.item.itemCode}}
          </b-link>
        </template>
    </b-table>
    <b-row>
        <b-col md="8" class="my-1">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
        <b-col md="4" class="my-1">
            <TableSize v-model="perPage" :totalRows="totalRows"/>
        </b-col>
    </b-row>

  </b-container>
</template>

<script>
import TableSize from '@/components/TableSize.vue'
import tableMixin from '@/mixins/table'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  components: { TableSize, ValidationObserver },
  mixins: [tableMixin],
  data () {
    return {
      showFilter: true,
      sortBy: 'itemCode',
      sortDesc: true,
      filter: {
        itemCode: { fieldType: 'input', value: {} },
        timeOfCreation: {
          fieldType: 'dateRange',
          value: {
            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            end: new Date()
          },
          format: 'ISO_UTC'
        },
        product: { fieldType: 'input', value: { equals: null } }
      },
      filterStr: null,
      sourceOptions: [
        { item: 0, name: this.$t('menu.surowce') },
        { item: 1, name: this.$t('menu.wyroby') },
        { item: null, name: this.$t('button.all.label') }
      ]
    }
  },
  watch: {
    currentLang (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$refs.table.refresh()
      }
    }
  },
  computed: {
    fields () {
      return [
        { key: 'itemCode', label: this.getColumnLabel('itemCode'), sortable: true, tdClass: 'getItemClass' }
      ]
    },
    ...mapGetters({
      currentLang: 'settings/currentLang'
    })
  }
}
</script>

<style>

</style>
