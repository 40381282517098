<template>
  <v-date-picker mode='range'
      show-caps
      ref="okres"
      :value="value"
      @input="$emit('input',$event)"
      :locale="currentLang"
      :input-props="inputProps">
  </v-date-picker>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: ['value'],
  computed: {
    ...mapGetters({
      currentLang: 'settings/currentLang'
    }),
    inputProps () {
      return { class: 'mb-2 mr-sm-2 mb-sm-2 form-control',
        style: 'min-width: 250px',
        placeholder: this.$t('date-picker.placeholder') }
    }

  }
  /* methods: {
    clearDragValue (event) {
      // dodane ze wzgledu na blad w v-calendar 0.9.7
      // (po wyjsciu z kalendarza w trakcie zaznaczania pokazuje sie nieprawidlowa data)
      // po zmianie wersji v-calendar moze nie byc potrzebne
      if (this.$refs.okres.dragValue) this.$refs.okres.dragValue = null
    }
  } */
}
</script>

<style>

</style>
