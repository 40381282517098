<template>
    <multiselect
        v-bind="$attrs"
        :loading="loadingCatalogGroups"
        :options="catalogGroups"
        :multiple="false"
        :clear-on-select="false"
        :preserve-search="true"
        label="description"
        track-by="itemcataloggroupid"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        @open="loadCatalogGroups">
    </multiselect>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
    mainGroupOnly: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loadingCatalogGroups: false,
      catalogGroups: []
    }
  },
  created () {
    this.loadCatalogGroups()
  },
  methods: {
    loadCatalogGroups () {
      if (this.catalogGroups && this.catalogGroups.length > 0) return

      this.loadingCatalogGroups = true
      this.$http.get('data/catalogGroups', { params: { 'mainGroupOnly': this.mainGroupOnly } })
        .then((response) => {
          this.catalogGroups = response.data.sort((a, b) => (a.description > b.description) ? 1 : -1)
          this.loadingCatalogGroups = false
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    }
  }
}
</script>

<style>

</style>
